<template>
	<ZyroPopupCard
		type="editor"
		:title="$t('builder.sectionSettings.title')"
		:tabs="$options.tabs"
		:current-tab="currentTab"
		@change="currentTab = $event"
		@close="$emit('close')"
	>
		<Component :is="currentTab.component" />
	</ZyroPopupCard>
</template>

<script>
import i18n from '@/i18n/setup';

import EditBlockTabBackground from './EditBlockTabBackground.vue';
import EditBlockTabLayout from './EditBlockTabLayout.vue';

const tabs = [
	{
		id: 'layout',
		title: i18n.t('common.layout'),
		component: 'EditBlockTabLayout',
	},
	{
		id: 'background',
		title: i18n.t('common.background'),
		component: 'EditBlockTabBackground',
	},
];

export default {
	components: {
		EditBlockTabBackground,
		EditBlockTabLayout,
	},
	props: {
		startTabId: {
			type: String,
			default: '',
		},
	},
	data() {
		return { currentTab: this.$options.tabs[0] };
	},
	created() {
		this.currentTab = this.$options.tabs
			.find((tab) => tab.id === this.startTabId)
			|| this.$options.tabs[0];
	},
	tabs,
};
</script>
