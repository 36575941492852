<template>
	<div class="layout">
		<ZyroFieldToggle
			v-if="!isMobileScreen && !isMobileView"
			:id="`${id}-toggle`"
			v-qa="`sectionsettings-toggle-${gapsEnabled ? 'on': 'off'}-enablegriddaps`"
			:checked="gapsEnabled"
			:label="$t('builder.editBlockTabLayout.toggleFieldLabel')"
			@change="gapsEnabled = !gapsEnabled"
		/>
		<ZyroCssShorthandControl
			v-qa="'sectionsettings-inputfield-topbottompadding'"
			:show-horizontal="isMobileScreen || isMobileView"
			:value.sync="padding"
			units="px"
			:label="$t('builder.editBlockTabLayout.controlRangeLabel')"
			bold
		/>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
	mapActions,
} from 'vuex';

import { cloneDeep } from '@/utils/object';

export default {
	data() {
		return {
			id: '',
			currentBlockBeforeEdit: null,
		};
	},
	computed: {
		...mapState(['currentBlockId']),
		...mapGetters(['currentBlock']),
		...mapState('gui', [
			'isMobileView',
			'isMobileScreen',
		]),
		padding: {
			set(newValue) {
				const key = this.isMobileView || this.isMobileScreen ? 'm-block-padding' : 'block-padding';

				this.setEditMode(false);
				this.setBlockData({
					blockId: this.id,
					data: { settings: { styles: { [key]: newValue } } },
				});
			},
			get() {
				const key = this.isMobileView || this.isMobileScreen ? 'm-block-padding' : 'block-padding';

				return this.currentBlock.settings.styles[key] || '0';
			},
		},
		gapsEnabled: {
			get() {
				return this.currentBlock.settings.styles['column-gap'] !== '0px'
				&& this.currentBlock.settings.styles['row-gap'] !== '0px';
			},
			set(newValue) {
				this.setEditMode(false);
				const rowSizeInt = Number.parseInt(this.currentBlock.settings.styles['row-size'], 10);

				if (newValue) {
					const gridGap = (this.currentBlock.settings.styles['grid-gap-history'] || '16px 16px').split(' ');
					const rowGapInt = Number.parseInt(gridGap[0], 10);

					this.setBlockData({
						blockId: this.id,
						data: {
							settings: {
								styles: {
									'row-gap': gridGap[0],
									'column-gap': gridGap[1],
									'row-size': `${rowSizeInt - rowGapInt}px`,
								},
							},
						},
					});
				} else {
					const rowGap = this.currentBlock.settings.styles['row-gap'];
					const columnGap = this.currentBlock.settings.styles['column-gap'];

					// Save old gaps
					this.currentBlock.settings.styles['grid-gap-history'] = `${rowGap} ${columnGap}`;
					const rowGapInt = Number.parseInt(rowGap, 10);

					this.setBlockData({
						blockId: this.id,
						data: {
							settings: {
								styles: {
									'column-gap': '0px',
									'row-gap': '0px',
									'row-size': `${rowSizeInt + rowGapInt}px`,
								},
							},
						},
					});
				}
			},
		},
	},
	mounted() {
		this.id = this.currentBlockId;
		this.currentBlockBeforeEdit = cloneDeep(this.currentBlock);
	},
	beforeDestroy() {
		this.pushBlockDataToHistory({
			blockId: this.id,
			oldData: this.currentBlockBeforeEdit,
		});
	},
	methods: {
		...mapMutations([
			'pushBlockDataToHistory',
			'setBlockData',
		]),
		...mapActions('gui', ['setEditMode']),
	},
};
</script>

<style lang="scss" scoped>
.layout {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	&__alignment {
		display: flex;

		> *:not(:last-child) {
			margin-right: 24px;
		}

		&-label {
			margin-top: 16px;
		}
	}
}
</style>
